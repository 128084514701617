import React, { useState, useEffect } from 'react';

const MyColorPlugin = () => {
  const [bgColor, setBgColor] = useState('');

  useEffect(() => {
    // Hintergrundfarbe aus den ACF-Feldern abrufen
    fetch('https://gutenberg-spa.senioren-aktivieren.de/wp-json/acf/v3/options/plugin_hintergrundfarbe')
      .then(response => response.json())
      .then(data => {
        if (data.acf && data.acf.plugin_hintergrundfarbe) {
          setBgColor(data.acf.plugin_hintergrundfarbe);
        }
      })
      .catch(error => console.error('Fehler beim Abrufen der Farbe:', error));
  }, []);

  return (
    <div style={{ backgroundColor: bgColor }}>
      <h1>Mein Plugin</h1>
      <p>Das ist mein React-Plugin mit dynamischem Hintergrund.</p>
    </div>
  );
};

export default MyColorPlugin;
