import React, { useEffect, useState } from 'react';
import TodoApp from './components/TodoApp';
import MyColorPlugin from './components/ColorPicker';
const MyComponent = () => {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await fetch('https://gutenberg-spa.senioren-aktivieren.de/wp-json/wp/v2/posts');
                const data = await response.json();
                setPosts(data);
            } catch (error) {
                console.error('Error fetching posts:', error);
            }
        };

        fetchPosts();
    }, []);

    return (
        <div className="mx-auto">
          <MyColorPlugin/>
          <TodoApp></TodoApp>
            
          <figcaption className="mt-10 flex flex-col items-center text-center">
  <img 
    className="mx-auto h-10 w-10 rounded-full" 
    src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" 
    alt="" 
  />
  <div className="text-gray-600 mt-8">CEO of Workcation</div>
  <div className="flex items-center justify-center mt-2">
    <div className="font-semibold">Judith Black</div>
    <svg viewBox="0 0 2 2" width="3" height="3" aria-hidden="true" className="fill-gray-900 mx-2">
      <circle cx="1" cy="1" r="1" />
    </svg>
  </div>
</figcaption>

          <div className="flex justify-center mt-8 p-8">
            <ul className="w-full md:w-1/2 space-y-4">
              {posts.map(post => (
                <li key={post.id} className="bg-white shadow-md rounded-lg p-4 hover:shadow-lg transition-shadow duration-200">
                <a href={post.link} target="_blank" rel="noopener noreferrer">
                  <h2 className="text-xl font-semibold text-gray-800">{post.title.rendered}</h2>
                </a>
              </li>
              ))}
            </ul>
          </div>





        </div>
    );
};

export default MyComponent;
