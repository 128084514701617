import React, { useEffect, useState } from 'react';

const TodoApp = () => {
    const [todos, setTodos] = useState([]);
    const [newTodo, setNewTodo] = useState('');

    useEffect(() => {
        fetchTodos();
    }, []);

    const fetchTodos = async () => {
        const response = await fetch('https://gutenberg-spa.senioren-aktivieren.de/wp-json/todos/v1/list');
        const data = await response.json();
        setTodos(data);
    };

    const deleteTodo = async (id) => {
        // Hier wird der DELETE-Request gesendet, um ein spezifisches Todo zu löschen
        const response = await fetch(`https://gutenberg-spa.senioren-aktivieren.de/wp-json/wp/v2/todos/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer YOUR_TOKEN', // Füge hier ein gültiges Token hinzu
            },
        });

        if (response.ok) {
            // Entferne das gelöschte Todo aus der Liste
            setTodos(todos.filter(todo => todo.id !== id));
            console.log('Todo gelöscht:', id);
        } else {
            console.error('Fehler beim Löschen des Todos:', response.statusText);
        }
    }

    const addTodo = async () => {
        if (!newTodo) return;

        await fetch('https://gutenberg-spa.senioren-aktivieren.de/wp-json/todos/v1/create', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ title: newTodo }),
        });

        setNewTodo('');
        fetchTodos(); // Refresh the todo list
    };

    return (
        <div className="mx-auto text-center mt-8 ">
            <h1 className="text-xl font-semibold text-gray-800">To-Do List by Alex</h1>
            <input
                type="text"
                value={newTodo}
                onChange={(e) => setNewTodo(e.target.value)}
                placeholder="Add a new to-do"
                className="text-xl bg-white shadow-md rounded-lg font-semibold text-gray-800 w-full md:w-1/2 space-y-4 p-8"
            />
            <button onClick={addTodo}
            className="ml-4 bg-green-500 text-white py-1 px-4 rounded hover:bg-blue-600"
            >Add</button>

            <div className="flex justify-center mt-8 p-8">
            <ul className="w-full md:w-1/2 space-y-4">
              {todos.map(todo => (
                <li key={todo.id} 
                
                className="flex justify-between bg-white shadow-md rounded-lg p-4 hover:shadow-lg transition-shadow duration-200 translate-y-4 animate-slide-up"
                >
                  <h2 className="text-xl font-semibold text-gray-800">{todo.title}</h2>
                  <button 
                            onClick={() => deleteTodo(todo.id)} 
                            className="ml-4 bg-red-500 text-white py-1 px-2 rounded">
                            Löschen
                        </button>
              </li>
              ))}
            </ul>
          </div>

            

          
        </div>
    );
};

export default TodoApp;
